import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { axiosClient } from "Api/axoisClient";
import Swal from "sweetalert2";
import { convertImageToBase64 } from "Helpers/ImageHelper";
import LoadingCom from "components/Loading/LoadingCom";
import { useTranslation } from "react-i18next";

const PlanModal = ({ open, setOpen, plan, action, setReload }) => {
  const { t } = useTranslation();
  const [planInfo, setPlanInfo] = useState({
    id: plan?.id || "",
    type: plan?.type || "",
    image: plan?.image || "",
    price: plan?.price || "",
    price_eur: plan?.price_eur || "",
    max_announcements: plan?.max_announcements || "",
    max_pinned_announcements: plan?.max_pinned_announcements || "",
    max_favorites_announcements: plan?.max_favorites_announcements || "",
    max_update: plan?.max_update || "",
  });
  const [errors, setErrors] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [priceOneEur, setPriceOneEur] = useState(1);

  const hundelChange = async (e) => {
    if (e.target.name == "image" && e.target?.files) {
      setPlanInfo({
        ...planInfo,
        image: await convertImageToBase64(e.target?.files[0]),
      });
    } else {

      if(e.target.name == 'price'){

        setPlanInfo((pre) => {
          return {
            ...pre,
            price_eur: Math.round((e.target?.value / priceOneEur) * 10) / 10,
          };
        });

      }

      setPlanInfo((pre) => {
        return {
          ...pre,
          [e.target.name]: e.target?.value || null,
        };
      });
    }
  };

  useEffect(() => {
    getExchangeRate()
  }, [])

  const updatePlan = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axiosClient.put(`/plans/${id}`, planInfo);

      if (res.status == 200) {
        setErrors([]);
        Swal.fire({
          icon: "success",
          title: t("plans.messages.updateSuccess"),
        });
        setReload((pre) => !pre);
        setOpen(false);
      }
    } catch (err) {
      if (err?.response?.status == 422) {
        setErrors(
          Object.values(err?.response?.data).map((item) => {
            return item[0];
          })
        );
      } else {
        setErrors([t("plans.messages.error.tryAgain")]);
      }
    }
    setIsLoading(false);
  };

  const createPlan = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await axiosClient.post(`/plans`, planInfo);

      if (res.status == 200) {
        setErrors([]);
        Swal.fire({
          icon: "success",
          title: t("plans.messages.createSuccess"),
        });
        setReload((pre) => !pre);
        setOpen(false);
      }
    } catch (err) {
      if (err?.response?.status == 422) {
        setErrors(Object.values(err?.response?.data));
      } else {
        setErrors([t("plans.messages.error.tryAgain")]);
      }
    }
    setIsLoading(false);
  };

    async function getExchangeRate() {
        try {
            const response = await axiosClient.get(`https://v6.exchangerate-api.com/v6/27754a5080eab6f66be48e8f/latest/USD`, {
                params: {
                    currencies: "EUR",
                    base_currency : "MAD"
                }
            });

            setPriceOneEur(response.data.conversion_rates.MAD);
        } catch (error) {
            console.error("Error fetching exchange rate:", error);
        }
    }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      sx={{
        zIndex: "999 !important",
        bgcolor: "#0000004f",
      }}
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-[50%] left-[50%] w-[98%] sm:w-[70%] lg:w-[55%] bg-slate-50 rounded-lg shadow-lg -translate-y-[50%] -translate-x-[50%]">
        <MDBox
          className=" flex justify-between rounded-t-lg p-5"
          variant="gradient"
          bgColor="info"
        >
          <Typography
            sx={{ color: "white !important" }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            {action === "create" ? t("plans.title.create") : t("plans.title.update")}
          </Typography>
          <CloseIcon
            onClick={() => setOpen(false)}
            className="text-white cursor-pointer"
          />
        </MDBox>
        <Box id="modal-modal-description" className="p-5 h-[85vh] hide-scrollbar overflow-y-scroll flex gap-y-8 flex-col">
          {IsLoading ? (
            <LoadingCom />
          ) : (
            <>
              {errors?.length > 0 && (
                <Box className="p-2 text-white flex flex-col rounded-lg bg-red-500">
                  {errors?.map((item, index) => (
                    <Typography fontSize={16} key={index} component="body2">
                      {item}
                    </Typography>
                  ))}
                </Box>
              )}

              <Typography
                sx={{ fontWeight: 500, fontSize: "14px" }}
                component="body2"
              >
                {t("plans.labels.unlimitedFieldNote")}
              </Typography>

              <Box className="flex items-center gap-3">
                <Typography
                  className="w-1/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.labels.badge")}
                </Typography>
                <Typography className="w-3/4" component="body2">
                  {planInfo.image ? (
                    <img
                      src={planInfo.image || null}
                      alt={planInfo.type || "Default plan"}
                      className="w-[40px] h-[40px]"
                    />
                  ) : (
                    <p className="text-[16px]">{t("plans.labels.noImage")}</p>
                  )}
                </Typography>
              </Box>

              <Box className="flex items-center gap-3">
                <Typography
                  className="w-1/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {action === "create" ? t("plans.labels.uploadImage") : t("plans.labels.modifyBadge")}
                </Typography>
                <Typography className="w-3/4" component="body2">
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => hundelChange(e)}
                    maxLength={1}
                  />
                </Typography>
              </Box>

              <Box className="flex items-center gap-3">
                <Typography
                  className="w-1/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.labels.type")}
                </Typography>
                <Input
                  value={planInfo?.type}
                  onChange={(e) => hundelChange(e)}
                  className="w-3/4"
                  name="type"
                  type="text"
                ></Input>
              </Box>

              <Box className="flex flex-col md:flex-row items-center gap-3">
                <Typography
                  className="w-full md:w-2/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.labels.maxAnnouncements")}
                </Typography>
                <Input
                  value={planInfo?.max_announcements}
                  onChange={(e) => hundelChange(e)}
                  className="w-full md:w-2/4"
                  name="max_announcements"
                  type="number"
                ></Input>
              </Box>

              <Box className="flex flex-col md:flex-row items-center gap-3">
                <Typography
                  className="w-full md:w-2/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.columns.maxUpdates")}
                </Typography>
                <Input
                  value={planInfo?.max_update}
                  onChange={(e) => hundelChange(e)}
                  className="w-full md:w-2/4"
                  name="max_update"
                  type="number"
                ></Input>
              </Box>

              <Box className="flex flex-col md:flex-row items-center gap-3">
                <Typography
                  className="w-full md:w-2/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.labels.maxPinnedAnnouncements")}
                </Typography>
                <Input
                  value={planInfo?.max_pinned_announcements}
                  onChange={(e) => hundelChange(e)}
                  className="w-full md:w-2/4"
                  name="max_pinned_announcements"
                  type="number"
                ></Input>
              </Box>

              <Box className="flex flex-col md:flex-row items-center gap-3">
                <Typography
                  className="w-full md:w-2/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.labels.price")}
                </Typography>
                <Input
                  value={planInfo?.price}
                  onChange={(e) => hundelChange(e)}
                  className="w-full md:w-2/4"
                  name="price"
                  type="number"
                ></Input>
              </Box>

              <Box className="flex flex-col md:flex-row items-center gap-3">
                <Typography
                  className="w-full md:w-2/4"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                  component="body2"
                >
                  {t("plans.labels.price_eur")}
                </Typography>
                <Input
                  value={planInfo?.price_eur}
                  onChange={(e) => hundelChange(e)}
                  className="w-full md:w-2/4"
                  name="price_eur"
                  type="number"
                ></Input>
              </Box>

              <Box className="flex justify-center">
                {action === "create" && (
                  <Button
                    onClick={(e) => createPlan(e)}
                    disabled={IsLoading}
                    variant="contained"
                    bgColor="info"
                    sx={{ color: "#ffffff" }}
                  >
                    {t("plans.buttons.create")}
                  </Button>
                )}

                {action === "update" && (
                  <Button
                    onClick={(e) => updatePlan(e, planInfo?.id)}
                    variant="contained"
                    disabled={IsLoading}
                    bgColor="info"
                    sx={{ color: "#ffffff" }}
                  >
                    {t("plans.buttons.update")}
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PlanModal;
