import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink, redirect } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ShopIcon from '@mui/icons-material/Shop';
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useSelector } from "react-redux";

// import Inbox from "components/Inbox/Inbox";
import { axiosClient } from "Api/axoisClient";
import { Logo_white } from "constants/images";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";


function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const {t} = useTranslation();
  const [controller, dispatch] = useMaterialUIController();
  const isLarge = useMediaQuery("(min-width:1200px)");
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const { isAdmin } = useSelector((state) => state.auth);
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, belongToAdmin }, index) => {
    
    let returnValue;
    
    if (type === "collapse" && (belongToAdmin === isAdmin || belongToAdmin === undefined )) {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  const redirectTo = async (e) => {
    e.preventDefault()
    
    try {
      const res = await axiosClient.post('/redirect-to', {
        url : `${process.env.REACT_APP_MARKET_PATH}/login-with-session`
      })

      if(res.status === 200){

        window.location.href = res.data.url

      }

    } catch (error) {
      
      if(error.response.status == (422 || 500)){
        Swal.fire({
          icon: "error",
          title: t("modals.command.messages.error.title"),
          text: t("modals.command.messages.error.text"),
        });
      }

    }

  }

  return (
    <>
      {
        isLarge ? (
          <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
          >
            <MDBox pt={3} pb={1} px={4} textAlign="center">
              <MDBox
                display={{ xs: "block", xl: "none" }}
                position="absolute"
                top={0}
                right={0}
                p={1}
                onClick={closeSidenav}
                sx={{ cursor: "pointer" }}
              >
                <MDTypography variant="h6" color="secondary">
                  <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                <MDBox component="img" src={Logo_white} alt="Brand" width="9rem" />
                {/* <MDBox
                  width={!brandName && "100%"}
                  sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                >
                  <img src="/favicon.png" /> 
                  <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                    {brandName}
                  </MDTypography>
                </MDBox> */}
              </MDBox>
            </MDBox>
            <Divider
              key='ghdg'
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
            <List>
              {renderRoutes}
      
              <MDBox className='relative' onClick={(e) => redirectTo(e)}>
                  <SidenavCollapse
                      name={t('goToMarget')}
                      icon={<ShopIcon/>}
                  />
              </MDBox>
              {/* <Inbox/> */}
      
            </List>
            {/* <MDBox p={2} mt="auto">
              <MDButton
                component="a"
                href="https://www.creative-tim.com/product/material-dashboard-pro-react"
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={sidenavColor}
                fullWidth
              >
                upgrade to pro
              </MDButton>
            </MDBox> */}
          </SidenavRoot>
        ) :
          <Box  className={`w-full ${darkMode ? 'bg-[#1f283e]' : ' bg-gradient-to-tr from-[#42424a] to-[#191919]' } rounded-lg mt-3 py-2 overflow-x-scroll flex items-center`}>
            {renderRoutes}
        
            <MDBox className='relative' onClick={(e) => redirectTo(e)}>
                <SidenavCollapse
                      name={t('goToMarget')}
                    icon={<ShopIcon/>}
                />
            </MDBox>
            {/* <Inbox/> */}
          </Box>
      }
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
