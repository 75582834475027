const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
    
            reader.onloadend = () => {
                resolve(reader.result); // استخدام resolve لإرجاع النتيجة عندما يكون جاهزًا
            };
    
            reader.onerror = reject; // إذا حدث خطأ، استخدم reject
    
            reader.readAsDataURL(file);
        } else {
            reject(new Error('No file provided')); // إذا لم يتم توفير ملف، استخدم reject
        }
    });
};

const convertArrayImagesToBase64 = (files) => {

    const Imagesbase64 = files.map((item) => {
        new Promise((resolve, reject) => {
            if (item) {
                const reader = new FileReader();
        
                reader.onloadend = () => {
                    resolve(reader.result); // استخدام resolve لإرجاع النتيجة عندما يكون جاهزًا
                };
        
                reader.onerror = reject; // إذا حدث خطأ، استخدم reject
        
                reader.readAsDataURL(item);
            } else {
                reject(new Error('No file provided')); // إذا لم يتم توفير ملف، استخدم reject
            }
        });
    }) 
    
    return Imagesbase64;

}

function calculateStayDetails(startDate, endDate) {
    
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const current = new Date().getTime();

    const totalDuration = (end - start) / (1000 * 60 * 60 * 24);

    const stayedDuration = (current - start) / (1000 * 60 * 60 * 24);

    const daysStayed = Math.min(stayedDuration, totalDuration);

    const remainingDays = Math.max(totalDuration - daysStayed, 0);

    const percentageRemaining = ((remainingDays / totalDuration) * 100).toFixed(2);

    return {
        totalDays: Math.ceil(totalDuration),
        remainingDays: Math.floor(remainingDays) + 1,
        percentageRemaining: `${percentageRemaining}%`
    };
  }

  function getType(type_id, t){

    const types = [
      {
        id : 1,
        name : t('register.step2.company_types.retailer')
      },
      {
        id : 2,
        name : t('register.step2.company_types.manufacturer')
      },
      {
        id : 3,
        name : t('register.step2.company_types.producer')
      },
      {
        id : 4,
        name : t('register.step2.company_types.service_provider')
      },
      {
        id : 5,
        name : t('register.step2.company_types.wholesaler')
      },
      {
        id : 6,
        name : t('register.step2.company_types.exporter')
      },
      {
        id : 7,
        name : t('register.step2.company_types.importer')
      },
      {
        id : 8,
        name : t('register.step2.company_types.large_account')
      },
      {
        id : 9,
        name : t('register.step2.company_types.reseller')
      },
      {
        id : 10,
        name : t('register.step2.company_types.startup')
      },
    ]
  
    return types?.find(item => item.id == type_id)?.name
  
  }

export {
    convertImageToBase64,
    convertArrayImagesToBase64,
    calculateStayDetails,
    getType
}