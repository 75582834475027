import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, FormControl, InputLabel, MenuItem, Select, CircularProgress } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { axiosClient } from "Api/axoisClient";
import { useSelector } from "react-redux";

const SalesStatistics = () => {
  
    const [year, setYear] = useState(getCurrentYear());
    const [chartData, setChartData] = useState([]);
    const company_id = useSelector((state) => state.auth.user.company_id);
  
    function getCurrentYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    }
  
    useEffect(() => {
      setYear(getCurrentYear());
    }, []);
  
    const fetchData = async () => {
  
      try {
        const response = await axiosClient.get(`statistics/sales/by-month/${company_id}/${year}`);
        const apiData = response.data.data;
  
        // Convert API data to chart-friendly format
        const totalSellsData = [];
        for (let i = 1; i <= 12; i++) {
          const monthData = apiData[String(i).padStart(2, '0')] ? apiData[String(i).padStart(2, '0')].sales_count : 0;
          totalSellsData.push({
            month : i,
            ventes : monthData
          });
        }
        setChartData(totalSellsData);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      if(year){
        fetchData(year);
      }
    }, [year]);
  
    const handleYearChange = (event) => {
      const selectedYear = event.target.value;
      setYear(selectedYear);
    };
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Box sx={{ width: "100%", textAlign: "center", padding: "20px" }}>
          <FormControl sx={{ mb: 3, minWidth: 200 }}>
            <InputLabel id="year-select-label">Sélectionnez l'année</InputLabel>
            <Select
              labelId="year-select-label"
              defaultValue={year}
              className="p-2"
              onChange={handleYearChange}
              label="Sélectionnez l'année"
            >
              <MenuItem value={year - 2}>{year - 2}</MenuItem>
              <MenuItem value={year - 1}>{year - 1}</MenuItem>
              <MenuItem value={year}>{year}</MenuItem>
              <MenuItem value={year + 1}>{year + 1}</MenuItem>
            </Select>
          </FormControl>
  
          <ResponsiveContainer width="100%" height={400}>
            <LineChart  data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis type="number"/>
              <Tooltip />
              <Legend
                content={() => (
                  <ul>
                    <li style={{ color: "#8884d8" }}>Nombre de ventes</li>
                  </ul>
                )}
              />
              <Line type="monotone" dataKey="ventes" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </DashboardLayout>
    );
}

export default SalesStatistics