import { CircularProgress, Modal, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { axiosClient } from 'Api/axoisClient'
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import SendIcon from '@mui/icons-material/Send';
import MDBox from 'components/MDBox';
import window from 'Api/echo';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const ContactModal = ({open, setOpen, userId}) => {

    const { t } = useTranslation();

    const boxRef = useRef(null)

    const [isLoading, setIsLoading] = useState(true);
    const [isSubLoading, setIsSubLoading] = useState(false);
    const [SuccessSend, setSuccessSend] = useState(false);
    const [newMessage, setnewMessage] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [MessageContent, setMessageContent] = useState('')

    const [currentPage, setcurrentPage] = useState(1);
    const [oldPosition, setOldPosition] = useState(0)

    // contact info
    const [userInfo, setUserInfo] = useState({
        name: '',
        email : ''
    });
    
    const [messages, setMessages] = useState([])
    const is_admin = JSON.parse(localStorage.getItem('isAdmin'));

    // useEffect(() => {

    //     const chatChannel = window.Echo.private('chat.' + my_id);

    //     chatChannel.listen('.MessageEvent', (data) => {
    //         setMessages((prevMessages) => [...prevMessages, data]);
    //         setnewMessage(true)
    //     });

    //     return () => {
    //         chatChannel.stopListening('.MessageEvent');
    //         window.Echo.leaveChannel('private-chat.' + my_id);
    //     };

    // }, []);

    useEffect(() => {

        getMessages(setIsLoading, 1)
        

    }, [])

    useEffect(()=>{

        if(!isLoading){
            boxRef.current.scrollTop = boxRef.current.scrollHeight;
        }

    }, [isLoading])

    useEffect(()=>{

        if(newMessage){
            boxRef.current.scrollTop = boxRef.current.scrollHeight;
            setnewMessage(false)
        }

    }, [newMessage])

    useEffect(()=>{

        if(!isSubLoading && boxRef?.current){
            boxRef.current.scrollTop = (boxRef.current.scrollHeight - oldPosition) ;
        }

    }, [isSubLoading])

    useEffect(()=>{

        if(!SuccessSend && boxRef?.current){
            boxRef.current.scrollTop = boxRef.current.scrollHeight;
        }

    }, [SuccessSend])

    const handleScroll = () => {
        
        if (boxRef.current.scrollTop === 0) {
            
            if(currentPage + 1 <= totalPages){

                setOldPosition(boxRef.current.scrollHeight)
                setcurrentPage(currentPage + 1)

                getMessages(setIsSubLoading, currentPage + 1);

            }

        }
    };

    const getMessages = async (setLoading, currentPage, reload = false)=>{

        setLoading(true)

        try {

            const res = await axiosClient.get(`/messages/${userId}/${currentPage}`)

            if(res.status == 200){

                if(messages?.length == 0 || reload){
                    setMessages(res.data.messages?.reverse())
                }else{
                    setMessages([...res.data.messages?.reverse(), ...messages])
                }

                setUserInfo({
                    name : res.data.user.full_name,
                    email : res.data.user.email,
                })

                setTotalPages(res?.data?.total_pages)

            }


        } catch (err) {
            console.log(err)
        }

        setLoading(false)

    }

    const sendMessage = async (e) => {

        e.preventDefault()

        if(MessageContent != ''){

            setSuccessSend(true)

            try {

                const res = await axiosClient.post('/messages', {
                    userId : userId,
                    message : MessageContent
                })

                if(res.status == 201){

                    setMessages((prevMessages) => [...prevMessages, res.data.data]);
                    setMessageContent("")
                    setSuccessSend(false)

                }

            } catch (err) {
                Swal.fire({
                icon: "error",
                title: t("modals.command.messages.error.title"),
                text: t("modals.command.messages.error.text"),
            });
            }

        }

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage(event);
        }
    };

    const reloadMessages = (event) => {
        event.preventDefault()
        setcurrentPage(1)
        getMessages(setIsLoading, 1, true)
    }

    return (
        <Modal
            className='relative'
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="absolute top-[50%] left-[50%] w-[98%] sm:w-[70%] lg:w-[55%] bg-slate-50 rounded-lg shadow-lg -translate-y-[50%] -translate-x-[50%]">
                
                {
                    isLoading ? 
                    (
                        <Box className='flex relative z-50 w-full h-[85vh] justtify-center items-center'>
                            <CircularProgress
                                className='fixed top-[50%] right-[50%]'
                                sx={{
                                    color : '#f9a607 !important',
                                    transform: 'translate(50%, -50%) !important'
                                }}
                            />
                        </Box>
                    )
                    :
                    (
                        <>
                            <MDBox 
                                variant="gradient"
                                bgColor="info"
                                className='flex items-center justify-between p-5'
                                sx={{
                                    borderRadius : '.5rem .5rem 0 0 !important',
                                }}
                                coloredShadow="info"
                            >
                        
                                <Box>
                                    <Typography className='capitalize' sx={{color : 'white !important'}} id="modal-modal-title" variant="h5" component="h2">
                                        {userInfo.name}
                                    </Typography>
                                    <Typography sx={{color : 'white !important'}} id="modal-modal-subtitle" variant="body2" component="h6">
                                        {userInfo.email}
                                    </Typography>
                                </Box>

                                <Box className="flex gap-4 text-[25px] items-center">
                                    <Tooltip title={t("contact.buttons.refresh")}>
                                        <CachedIcon onClick={(e) => reloadMessages(e)} className="text-white cursor-pointer"/>
                                    </Tooltip>
                                    <CloseIcon onClick={() => setOpen(false)} className="text-white cursor-pointer"/>
                                </Box>
                            </MDBox>
                            <Box onScroll={handleScroll} ref={boxRef} id="modal-modal-description" className='p-5 items-between pb-20 flex h-[85vh] hide-scrollbar overflow-y-scroll gap-y-8 flex-col'>

                                    {
                                        isSubLoading && (
                                            <Box className='flex w-full py-1 justify-center'>
                                                <CircularProgress
                                                    sx={{
                                                        color : '#f9a607 !important',
                                                    }}
                                                    size={30}
                                                />
                                            </Box>
                                        )
                                    }

                                    {
                                        messages?.map((item) => (
                                            <Box
                                                className={`
                                                    ${
                                                        userInfo.email == item?.receiver_email ? 
                                                            'bg-second' 
                                                        :
                                                            userInfo.email == item?.sender_email && 'bg-gray-500' 
                                                    } 
                                                    ${
                                                        userInfo.email == item?.receiver_email ? 
                                                            'self-end' 
                                                        :
                                                            userInfo.email == item?.sender_email && 'self-start' 
                                                    }
                                                    text-white text-sm relative p-3 rounded-lg w-fit max-w-[70%]
                                                `}
                                            >
                                                <p>
                                                    {item?.message}
                                                </p>

                                                <p className={`
                                                    ${
                                                        userInfo.email == item?.receiver_email ? 
                                                            '-left-16 text-gray-500 text-end' 
                                                        :
                                                            userInfo.email == item?.sender_email && '-right-16 text-gray-500 text-start' 
                                                    }
                                                    w-fit absolute bottom-0
                                                `}>
                                                    <span className="block">{item?.time?.slice(0, 9)}</span>
                                                    <span>
                                                        {item?.time?.slice(9, 15)}
                                                    </span>
                                                </p>

                                            </Box>
                                        ))
                                    }

                                
                            </Box>
                        </>
                    )
                }

                {
                    !isLoading && is_admin ? (
                        <Box className="flex fixed justify-between px-4 py-2 w-full items-center bottom-0 right-0">

                            <input 
                                value={MessageContent}
                                tabIndex="0" onKeyDown={(e) => handleKeyPress(e)}
                                onChange={(e) => setMessageContent(e.target.value)}
                                placeholder={t("contact.messages.enterMessage")}
                                className='w-[90%] focus:outline-none px-5 text-base p-2 border rounded-full'
                            />

                            <button disabled={MessageContent == ''} onClick={(e) => sendMessage(e)} className={`bg-second ${MessageContent != '' ? 'opacity-100' : 'opacity-70'} text-[25px] rounded-full text-white py-3 w-[50px] h-[50px] flex justify-center items-center`}
                                title={t("contact.buttons.send")}
                            >
                                <SendIcon className=' -rotate-12'/>
                            </button>

                        </Box>
                    ) : null
                }

            </Box>
        </Modal>
    )
}

export default ContactModal