import { axiosClient } from 'Api/axoisClient'
import LoadingCom from 'components/Loading/LoadingCom'
import React, {useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import { setUser, login } from "../store/slices/authSlice";
import { setCompany } from "../store/slices/companySlice";
import { getComapnyInfo } from "Api/company";
import { useTranslation } from 'react-i18next';

const LoginWithSession = () => {

    const {token} = useParams()
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        
        (async()=>{

            try {
                
                const res = await axiosClient.post('/loginSession', {
                    session_token : token
                })

                if(res.status == 200){

                    const token = res.data.access_token;
                    const user = res.data.user;

                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('token', token)

                    dispatch(login(token));
                    const company = await getComapnyInfo(user.company_id, token);
                    dispatch(setUser({ user: user, isAdmin: user?.privileges === 'admin' }));
                    dispatch(setCompany(company.data));
                
                    // Navigate to appropriate dashboard
                    navigate(user?.privileges === 'admin' ? 'bw-admin-wdash' : 'company');
                    
                }

            } catch (error) {
                
                Swal.fire({
                    icon: "error",
                    title: t("modals.command.messages.error.title"),
                    text: t("modals.command.messages.error.text"),
                });
                window.location.href = process.env.REACT_APP_MARKET_PATH
            }

        })()

    }, [])
    

  return (
    <div className='h-screen w-screen flex items-center justify-center'>
        <LoadingCom/>
    </div>
  )
}

export default LoginWithSession